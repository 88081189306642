


 html {
  --rainbow:  rgb(255, 0, 0), 
  rgb(238, 255, 0), 
  rgb(0, 255, 85), 
  rgb(0, 47, 255), 
  rgb(119, 0, 255), 
  rgb(255, 0, 0) 
}

.Board {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  margin: 0 auto;
}

.Card {
  width: 100px;
  height: 100px;
  perspective: 600px;
  margin: 5px;
  display: inline-block;
}

.Card div {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.front {
  background-color: #2e7d32;
}

.back {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #2e7d32;
}

.flipped .front {
  transform: rotateY(180deg);
}

.flipped .back {
  transform: rotateY(0);
}

.memory-container {
  height: 50vh;
  width: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 3em;
  background-color: rgb(0, 12, 12);
  border: 1px solid white;
  
}
.stats-container {
  color: aliceblue;

}

.card-container {
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    gap: 1rem;
}

.flip-card {
  display: grid;
  height: 110px;
  width: 85px;
  cursor: pointer;
}



.red {
  background-color: red;
}

.green {
  background-color: green;
}

.blue {
  background-color: blue;
}

.yellow {
  background-color: yellow;
}

.orange {
  background-color: orange;
}

.purple {
  background-color: purple;
}

.hidden {
  background: repeating-conic-gradient(
    var(--rainbow) 
    30deg);
}

.timer {
  color: #fff;
} 



