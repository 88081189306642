html {
  --pink: #ff00d4;
  --black: #000000;
  --blue: #0000ff;
  --rainbow:  #f10c0c,
              #133eff,
              #990099,
              #06a36f,
              #501cca,
              #022ff8,
              #d130d1,
              #5eff00,
              #f10c0c,
              #ff9913,
              #e6f702,
              #06a36f,
              #0628e7,
              #c702f8,
              #f112ba,
              #c70d0d,
              #d67e0b,
              #ecf842,
              #17d155,
              #1e1bf0,
              #c218ec,
              #c42574;
  --maxWidth: 1000px;
  --bs: 0 12px 24px 0 rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  font-size: 10px;
}



 *,
*:before,
*:after {
  box-sizing: inherit;
}

html, #root {
  background-color: var(--dark-background);
  color: var(--dark-text);
}

#root {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana,sans-serif;
  padding: 0;
  margin: 0;
  font-size: 1.25rem;
  line-height: 2;
}


ul {
  list-style-type: square;
}

.git-links {
  list-style-type: none;
}

li a {
  text-decoration: none;
  color: var(--pink);
}

li a:hover {
  text-decoration: underline;
  color: var(--pink);
} 

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

a {
  color: var(--pink);
  transition: color 0.3s ease;
}

a:hover {
  color: var(--blue);
}

@media (max-width: 600px) {
  body {
    font-size: 18px;
  }
}


.theme-dark {
  --dark-text: #000000;
  --light-text: #F9F8F8;  
  --dark-background: #000000;
  --light-background: #586F7C;
  --accent: #B8DBD9;
  --button-border: #B8DBD9;
}
.theme-light {
  --dark-text: #000000;
  --light-text: #5E4B56;
  --dark-background: #ffffff;
  --light-background: #ffffff;
  --accent: #DBE7E4;
  --button-border: #5E4B56;
}
  