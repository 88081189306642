.cat-box {
  color: rgb(255, 29, 29);
  height: 1000px;
}

h1 {
  color: rgb(255, 29, 29);
  padding: 10px;
}

fieldset {
  border: 1px solid #d21fe2;
  border-radius: 4px;
  padding: 10px;
}

input {
  border: 1px solid #d21fe2;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}


.add-name {
  background-color: #1f22e2;
  color: white;
  border: 5px solid #1fe297;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  font-size: 28px;
  margin: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat-names {
  display: flex;
  align-items: center;
  font-size: 2em;
  list-style: none;
  width: ;
}


.cat-names :nth-last-child(1) {
  margin-left: 0.5em;
}
