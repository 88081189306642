.games-container {
    display: flex;
    padding: 3em;
    margin-top: 1em;
    gap: 25px;
}

.game-card {
    
    outline: 3px solid rgb(245, 10, 61);
}

.link-button {
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.link-button button {
    background-color: rgb(233, 11, 11);
    color: rgb(0, 0, 0);
}