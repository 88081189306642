
.cv-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}



h2, h4, h5 {
  margin-bottom: 0.75em;
}


p {
  margin-bottom: 1em;
}


ul {
  list-style-type: none;
  padding: 0;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 1em;
}

.cv-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 3px black;
}