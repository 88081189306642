.title {
  background: linear-gradient(
      -66deg,
      var(--rainbow)
    );
  background-size: 600%;
  font-family: monospace;
  font-weight: bolder;
  font-size: 1.2em;
  letter-spacing: -6px;
  text-decoration: underline wavy 4px var(--pink);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glimmer 6s ease-in-out infinite;
  -moz-animation: glimmer 6s ease-in-out infinite;
  -webkit-animation: glimmer 6s ease-in-out infinite;
    
}

.title:hover {
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
0% {
  text-decoration: underline dotted 4px var(--pink);
}
12% {
  text-decoration: underline dotted 4.5px rgb(226, 95, 7);
 }
25% {
 text-decoration: underline dotted 5px rgb(236, 240, 6);
}
37% {
  text-decoration: underline dotted 5.5px rgb(100, 236, 10);
 }
50% {
  text-decoration: underline dotted 6px rgb(8, 81, 240);
}
62% {
  text-decoration: underline dotted 5.5px rgb(180, 15, 221);
 }
75% {
 text-decoration: underline dotted 5px rgb(199, 11, 67);
}
87% {
  text-decoration: underline dotted 4.5px rgb(252, 2, 2);
 }
100% {
  text-decoration: underline dotted 4px var(--pink);
}
}

@keyframes glimmer {
  0% {
    background-position: 0px 50%;
  }
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}