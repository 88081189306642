
#contact-form {
    background: #E9F1FE;
    position: absolute;
    background: var(--blue);
    color: #FFF;
    font-family: sans-serif;
    padding: 20px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 
          -10px -10px 2px rgba(247, 3, 3, 0.75),
          10px -10px 2px rgba(1, 111, 255, 0.75),
          10px 10px 2px rgba(14, 204, 14, 0.75),
          -10px 10px 2px rgba(251, 255, 3, 0.75);
}

#contact-form:hover {
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      box-shadow: 
        -10px -10px 2px rgba(247, 3, 3, 0.75),
        10px -10px 2px rgba(1, 111, 255, 0.75),
        10px 10px 2px rgba(14, 204, 14, 0.75),
        -10px 10px 2px rgba(251, 255, 3, 0.75);
      }
      25% {
        box-shadow: 
        -10px -10px 2px rgba(251, 255, 3, 0.75),
        10px -10px 2px rgba(247, 3, 3, 0.75),
        10px 10px 2px rgba(1, 111, 255, 0.75),
        -10px 10px 2px rgba(14, 204, 14, 0.75);
      }
      50% {
        box-shadow: 
        -10px -10px 2px rgba(14, 204, 14, 0.75),
        10px -10px 2px rgba(251, 255, 3, 0.75),
        10px 10px 2px rgba(247, 3, 3, 0.75),
        -10px 10px 2px rgba(1, 111, 255, 0.75);
      }
      75% {
        box-shadow: 
        -10px -10px 2px rgba(1, 111, 255, 0.75),
        10px -10px 2px rgba(14, 204, 14, 0.75),
        10px 10px 2px rgba(251, 255, 3, 0.75),
        -10px 10px 2px rgba(247, 3, 3, 0.75);
      }
      100% {
        box-shadow: 
        -10px -10px 2px rgba(247, 3, 3, 0.75),
        10px -10px 2px rgba(1, 111, 255, 0.75),
        10px 10px 2px rgba(14, 204, 14, 0.75),
        -10px 10px 2px rgba(251, 255, 3, 0.75);
      }
    }

#contact-form  input,
    textarea {
        width: 100%;
        border: 0;
        padding: 10px 20px;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    #contact-form textarea {
        height: 100px;
        resize: vertical;
    }

    #contact-form  button {
        width: 100%;
        padding: 10px;
        border: 0;
        cursor: pointer;
        background: var(--pink);
        font-weight: bold;
        color: #FFF;
        font-size: 18px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    #contact-form  span {
        display: block;
        text-align: center;
    } 

  