:root {
  
    /* --blue-border: #0ab2be; */
    --blue-color: #6e0546;
    --red-background: #dd0c0c;
    /* --red-border: #d41a1a; */
    --pink-border: #06f51a;
    --black-color: #070914;
    --pink-border: #da1598;

   

}



@keyframes reverse {
    0% {
        left: 47px;
        width: 40px;
    }
    60% {
        left: 3px;
        width: 80px;
    }
    100% {
        left: 3px;
    }
}
    
@keyframes switch {
    0% {
        left: 3px;
    }
    50% {
        left: 3px;
        width: 80px;
    }
    100% {
        left: 44px;
        width: 30px;
    }
}

.toggle--checkbox {
    opacity: 0;
}


.container--toggle {
    display: flex;
    flex-direction: row-reverse;
    
    
}


.toggle--label {
    width: 85px;
    height: 42px;
    background: var(--black-color);
    border-radius: 100px;
    border: 3px solid var(--pink-border);
    display: flex;
    position: relative;
    color: var(--black-color);
    cursor: pointer;
    
}

.toggle--checkbox:checked + .toggle--label:before {
    animation-name: reverse;
    /* animation-duration: 200ms; */
    /* animation-fill-mode: forwards; */
    /* transition: all 260ms ease-in; */
  /* background: var(--red-background);  */
    /* border-color: var(--red-border); */
} 

.toggle--label:before {
    animation-name: switch;
    animation-duration: 140ms;
    animation-fill-mode: forwards;
    content: '';

    width: 30px;
    height: 30px;

    border: 3px solid;
    top: 1px;
    left: 3px;
    position: absolute;
    border-radius: 90%;
    background: var(--red-background);
}



