
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  margin: 10px;
  border-radius: 10px;
  box-shadow: 
    -10px -10px 2px rgba(247, 3, 3, 0.75),
    10px -10px 2px rgba(1, 111, 255, 0.75),
    10px 10px 2px rgba(14, 204, 14, 0.75),
    -10px 10px 2px rgba(251, 255, 3, 0.75);

  overflow: hidden;
  width: 75%;
}

.card-button {
  background-color: rgb(233, 11, 11);
  color: rgb(0, 0, 0);
}

.card:hover {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    box-shadow: 
      -10px -10px 2px rgba(247, 3, 3, 0.75),
      10px -10px 2px rgba(1, 111, 255, 0.75),
      10px 10px 2px rgba(14, 204, 14, 0.75),
      -10px 10px 2px rgba(251, 255, 3, 0.75);
    }
    25% {
      box-shadow: 
      -10px -10px 2px rgba(251, 255, 3, 0.75),
      10px -10px 2px rgba(247, 3, 3, 0.75),
      10px 10px 2px rgba(1, 111, 255, 0.75),
      -10px 10px 2px rgba(14, 204, 14, 0.75);
    }
    50% {
      box-shadow: 
      -10px -10px 2px rgba(14, 204, 14, 0.75),
      10px -10px 2px rgba(251, 255, 3, 0.75),
      10px 10px 2px rgba(247, 3, 3, 0.75),
      -10px 10px 2px rgba(1, 111, 255, 0.75);
    }
    75% {
      box-shadow: 
      -10px -10px 2px rgba(1, 111, 255, 0.75),
      10px -10px 2px rgba(14, 204, 14, 0.75),
      10px 10px 2px rgba(251, 255, 3, 0.75),
      -10px 10px 2px rgba(247, 3, 3, 0.75);
    }
    100% {
      box-shadow: 
      -10px -10px 2px rgba(247, 3, 3, 0.75),
      10px -10px 2px rgba(1, 111, 255, 0.75),
      10px 10px 2px rgba(14, 204, 14, 0.75),
      -10px 10px 2px rgba(251, 255, 3, 0.75);
    }
  }



