body {
    background-color: rgb(11, 190, 190);
}

h1 { 
    display: flex;
}

.gameContainer {
    height: 50vh;
    width: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 3em;
    background-color: rgb(0, 12, 12);
    border: 1px solid white;
}

.playerDisplay {
    color: rgb(77, 255, 33);
    
    
}

.heart {
    height: 100%;
    margin-left: auto;
    animation: beat 0.8s ease-in-out infinite; 
}

@keyframes beat {
    0%, 50%, 100% { transform: scale(1.3, 1.5); }
    25%, 75% { transform: scale(0.80, 0.80); }
}

  
.board {
    display: flex;
    padding: 2em;
    position: relative;
    
}

.column {
    display: flex;
    flex-direction: column;
}

.tile {
    width: 50px;
    height: 50px;
    border: 2px dotted rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}

.player {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    animation: drop .5s linear forwards;
}

@keyframes drop {
    0% {transform: translateY(-250px) scaleY(0.9); opacity: 0;}
    10% {opacity: .4;}
    20% {opacity: .6;}
    40% {opacity: .8;}
    50% {transform: translateY(0px) scaleY(1); opacity: 1;}
    65% {transform: translateY(-17px) scalex(.8); opacity: 1;}
    75% {transform: translateY(-22px) scaleY(.9); opacity: 1;}
    100% {transform: translateY(0px) scaleY(1); opacity: 1;}
  }

.player-1 {
    background-color: rgb(255, 53, 2);
}

.player-2 {
    background-color: rgb(0, 255, 255);
}

.button-container {
    display: flex;
    align-items: flex-end;
  
   
}

button, input[type="button"] {
    padding: 10px;
    margin-top: 5px;
    border-radius: 10px;
    font-size: 2em;
    background-color: rgb(178, 33, 245);
    color: azure;
   
}